(function($){
	const siteName = 'kuroda-group';
	const a = 'is-active';
	const v = 'is-visible';
	const f = 'is-fixed';
	const slideSpeed = '400';

	KURODA_HD = {
		va: {
			pathname: location.pathname,
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		localDecision(){
			var regExp = new RegExp(`(test\\.${siteName}\\.connecty\\.com|copre\\.jp|localhost|192\\.168\\.0\\.|192\\.168\\.11\\.|192\\.168\\.1\\.)`, 'g');
			return regExp.test(location.hostname);
		},
		localLoading(){
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var i, j;
			var includeClass = document.querySelectorAll(`[class*="${key}"]`);
			var includeClassLen = includeClass.length;

			for(i=0; i < includeClassLen; i++) {
				var path = includeClass[i].innerHTML.split(' ')[1];
				ajaxArray.push(path);
			}
			var ajaxLen = ajaxArray.length;

			if(ajaxLen > 0) {
				for(i=0; i < ajaxLen; i++) {
					ajaxArrayList[i] = $.ajax({
						type: 'GET',
						url: ajaxArray[i]+'.html',
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done((...args) => {
					var regExp = new RegExp(key);

					for(i=0; i < args.length; i++) {
						var result = args[i];
						var position = $(result[0]).filter(':first').attr('class').split(' ');

						for(j=0; j < position.length; j++) {
							if(position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						$('.'+position).html(result[0]).children().unwrap();

						console.log('Succeeded to read the include file!:', position);
					}
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		catchAjaxError(_errorThrown){
			if(window.ga) {
				window.ga('send', 'event', 'ajaxError', location.href, _errorThrown, 0, true);
			}
		},
		getQuery(){
			var vars = [];
			var hash = null;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for(var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},
		nextToggle(){
			var $switch = $('.js-toggle');
			let _lang = location.pathname.split('/')[2];

			$switch.on('click', function(){
				if(!$(this).hasClass(a)) {
					$(this).addClass(a);
					$(this).next('.js-toggleBlock').stop().slideDown(slideSpeed);
				} else {
					$(this).removeClass(a);
					$(this).next('.js-toggleBlock').stop().slideUp(slideSpeed);
				}
			});
			if ($(this).parent().parent().find(".open").length === 0) {
				//1個でも閉じていたらすべて開くに切り替え
				$(this).parent().parent().find("h5").removeClass("openall");
				$(this).parent().parent().find("h5").addClass("closeall");
				if(!(_lang == 'en')) {
					$(this).parent().parent().find("a").html("すべて閉じる");
				} else {
					$(this).parent().parent().find("a").html("All Close");
				}
			  } else {
				$(this).parent().parent().find("h5").removeClass("closeall");
				$(this).parent().parent().find("h5").addClass("openall");
				if(!(_lang == 'en')) {
					$(this).parent().parent().find("a").html("すべて開く");
				} else {
					$(this).parent().parent().find("a").html("All Open");
				}
			  }
			for(var i = 0; i < $('.js-toggle-allWrap').length; i++) {
				let itemwrap = $('.js-toggle-allWrap').eq(i);
				let itemlength = $('.js-toggle-allWrap').eq(i).find('.js-toggle-all-item').length;
				let btn = $('.js-toggle-allWrap').eq(i).find('.js-toggle-all');
				btn.on('click', function(){
					if(!btn.hasClass(a)) {
						btn.addClass(a);
						for(var l = 0; l < itemlength; l++) {
							itemwrap.find('.js-toggle-all-item').eq(l).addClass(a);
							itemwrap.find('.js-toggle-all-item').eq(l).next('.js-toggle-all-itemBlock').stop().slideDown(slideSpeed);
						}
						if(!(_lang == 'en')) {
							btn.find('span').text('すべて閉じる');
						} else {
							btn.find('span').text('All Close');
						}
					} else {
						btn.removeClass(a);
						for(var l = 0; l < itemlength; l++) {
							itemwrap.find('.js-toggle-all-item').eq(l).removeClass(a);
							itemwrap.find('.js-toggle-all-item').eq(l).next('.js-toggle-all-itemBlock').stop().slideUp(slideSpeed);
						}
						if(!(_lang == 'en')) {
							btn.find('span').text('すべて開く');
						} else {
							btn.find('span').text('All Open');
						}
					}
				});
			}
			$('.js-toggle-all-item').on('click', function(){
				if(!$(this).hasClass(a)) {
					$(this).addClass(a);
					$(this).next('.js-toggle-all-itemBlock').stop().slideDown(slideSpeed);
				} else {
					$(this).removeClass(a);
					$(this).next('.js-toggle-all-itemBlock').stop().slideUp(slideSpeed);
				}
				setTimeout(() => {
					if ($(this).parents('.js-toggle-allWrap').find('.js-toggle-all-item').length == $(this).parents('.js-toggle-allWrap').find('.js-toggle-all-item.is-active').length) {
						$(this).parents('.js-toggle-allWrap').find('.js-toggle-all').addClass(a);
						if(!(_lang == 'en')) {
							$(this).parents('.js-toggle-allWrap').find('.js-toggle-all').find('span').text('すべて閉じる');
						} else {
							$(this).parents('.js-toggle-allWrap').find('.js-toggle-all').find('span').text('All Close');
						}
					} else {
						$(this).parents('.js-toggle-allWrap').find('.js-toggle-all').removeClass(a);
						if(!(_lang == 'en')) {
							$(this).parents('.js-toggle-allWrap').find('.js-toggle-all').find('span').text('すべて開く');
						} else {
							$(this).parents('.js-toggle-allWrap').find('.js-toggle-all').find('span').text('All Open');
						}
					}
				}, 100);
			});

		},
		easeScroll(){
			$('a[rel="scroll"]').on('click', (event) => {
				var speed = 1200;
				if (/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href, speed);
				}
				return false;
			});
			function scrollToObject(_self, _speed){
				var hash = '#' + _self.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

				if (hash !== '#pagetop') {
					if (KURODA_HD.va.window.width < KURODA_HD.va.device.sp) {
						pos -= 65;
					} else {
						pos -= 100;
					}
				}

				$('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
			}
		},
		easeScrollHash() {
			const hash = location.hash;
			if (hash) {
				$(window).on('load', () => {
					$('html, body').css('display', 'none');

					setTimeout(() => {
						$('html, body').css('display', 'block');

						let _hash = '#' + hash.split('#')[1];
						let pos = $(_hash).offset().top;

						if (KURODA_HD.va.window.width < KURODA_HD.va.device.sp) {
							pos -= 65;
						} else {
							pos -= 100;
						}
						$('html, body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
					}, 100);
				});
			}
		},
		pageTop(){
			var $pagetop = $('.footer-pagetop');

			$(window).scroll(function(e){
				var $this = $(e.currentTarget);
				// var $footer = $('.footer');
				// var footerPos = $footer.offset().top + $footer.height();
				var thisScroll = $this.scrollTop();
				// var pagetopPos = $this.height() + $footer.height() + thisScroll;

				if (thisScroll > '150') {
					$pagetop.addClass(v);
				} else {
					$pagetop.removeClass(v);
				}
			});
		},
		pcHeaderNav(){
			if (document.querySelectorAll('.header-nav-pc').length) {
				const $header = document.querySelector('.header');
				const $megabtn = document.querySelectorAll('.header-nav-pc-btn');
				const $megamenu = document.querySelectorAll('.header-nav-pc');
				const closeAllMegaMenus = () => {
					$header.classList.remove(a);
					$megabtn.forEach(btn => {
						btn.classList.remove(a);
						if (btn.nextElementSibling) {
							btn.nextElementSibling.classList.remove(a);
						}
					});
				};
				// メガメニューボタンのクリックイベント処理
				$megabtn.forEach(btn => {
					btn.addEventListener('click', (e) => {
						const clickedBtn = e.currentTarget;
						const clickedNav = clickedBtn.nextElementSibling; // 対応する .header-nav-pc
						// 他のメガメニューを閉じる
						$megabtn.forEach(otherBtn => {
							if (otherBtn !== clickedBtn) {
								otherBtn.classList.remove(a);
								if (otherBtn.nextElementSibling) {
									otherBtn.nextElementSibling.classList.remove(a);
								}
							}
						});
						// クリックされたメガメニューの開閉
						if (clickedBtn.classList.contains(a)) {
							$header.classList.remove(a);
							clickedBtn.classList.remove(a);
							// clickedNavが存在することを確認してから操作
							if (clickedNav) {
								clickedNav.classList.remove(a);
							}
						} else {
							$header.classList.add(a);
							clickedBtn.classList.add(a);
							if (clickedNav) {
								clickedNav.classList.add(a);
							}
						}
					});
				});
				// メガメニュー外のクリックで閉じる
				document.addEventListener("click", (event) => {
					const target = event.target;
					const isClickInsideNav = [...$megamenu]
						.some(el => el.contains(target));
					const isClickOnButton = [...$megabtn]
						.some(btn => btn.contains(target));

					if (!isClickInsideNav && !isClickOnButton && $header.classList.contains(a)) {
						closeAllMegaMenus();
					}
				});
				// フォーカスが外れた時のメニュー制御を追加
				$megabtn.forEach(btn => {
					const menu = btn.nextElementSibling;
					if (menu) {
						// フォーカスが .header-nav-pc 内にあるかチェックする関数
						const isInsideAnyHeaderNav = (element) => {
							return Array.from($megamenu).some(headerNav => headerNav.contains(element));
						};
						// フォーカスが外れた時にメニューを閉じる
						menu.addEventListener('focusout', () => {
							setTimeout(() => {
								const focusedElement = document.activeElement;
								const isInsideNav = menu.contains(focusedElement);
								const isInsideButton = btn.contains(focusedElement);
								const isInsideHeaderNav = isInsideAnyHeaderNav(focusedElement);
								// .header-nav-pc 内にフォーカスがない場合に閉じる
								if (!isInsideNav && !isInsideButton && !isInsideHeaderNav) {
									closeAllMegaMenus();
								}
							}, 0);
						});
						// TabキーまたはShift+Tabで移動した際にメニューを閉じる
						document.addEventListener('keydown', (e) => {
							if (e.key === 'Tab') {
								setTimeout(() => {
									const focusedElement = document.activeElement;
									const isInsideNav = menu.contains(focusedElement);
									const isInsideButton = btn.contains(focusedElement);
									const isInsideHeaderNav = isInsideAnyHeaderNav(focusedElement);
									// .header-nav-pc 内にフォーカスがない場合に閉じる
									if (!isInsideNav && !isInsideButton && !isInsideHeaderNav) {
										closeAllMegaMenus();
									}
								}, 0);
							}
						});
					}
				});
				// ESCキーでメガメニューを閉じる
				window.addEventListener("keydown", function (event) {
					if ($header.classList.contains(a)) {
						if (event.key === "Escape") {
							event.preventDefault();
							closeAllMegaMenus();
						}
					}
				});
			}
		},
		spHeaderHamburger(){
			if (document.querySelectorAll('.header-hamburger').length) {
				const $header = document.querySelector('.header');
				const $hamburger = document.querySelector('.header-hamburger');
				const $nav = document.querySelector('.header-nav-sp');
				const $lang = document.querySelector('.header-nav-sub-lang');
				const $container = document.querySelector('.container');
				let scrollTopNow;
				const fixedOnContainer = () => {
					scrollTopNow = window.scrollY;
					$container.querySelector('.contents').style.opacity = 0;
					$container.style.position = 'fixed';
					$container.style.width = '100%';
					$container.style.top = -1 * scrollTopNow;
					$container.style.left = 0;
					document.body.classList.add(f);
				};
				const fixedOffContainer = () => {
					$container.querySelector('.contents').style.opacity = 1;
					$container.style.position = 'static';
					document.body.classList.remove(f);
					window.scrollTo({ top: scrollTopNow });
				};
				$hamburger.addEventListener('click', (e) => {
					if ($nav.classList.contains(a)) {
						$nav.classList.remove(a);
						$lang.classList.remove(a);
						$hamburger.classList.remove(a);
						$hamburger.querySelector('span').textContent = 'メニュー'
						fixedOffContainer();
					} else {
						fixedOnContainer();
						$nav.classList.add(a);
						$lang.classList.add(a);
						$hamburger.classList.add(a);
						$hamburger.querySelector('span').textContent = '閉じる'
					}
				});
				window.addEventListener("keydown", function (event) {
					if ($nav.classList.contains(a)) {
						// タブキーが押された時
						if (event.key === "Tab") {
							event.preventDefault();
							// ヘッダー要素内のフォーカス可能な要素の一覧を取得
							const focusableElementsSelector = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]';
							const focusableElements = Array.from(
								$header.querySelectorAll(focusableElementsSelector)
							);
							// 現在のフォーカス位置を取得
							const focusedItemIndex = focusableElements.indexOf(document.activeElement);
							// shiftキーと同時に押されてた場合
							if (event.shiftKey) {
								if (focusedItemIndex === 0) {
									// 現在のフォーカスが最初の要素の場合、最後の要素にフォーカスを移動
									focusableElements[focusableElements.length - 1].focus();
								} else {
									// 現在のフォーカスが最初の要素以外の場合、前の要素にフォーカスを移動
									focusableElements[focusedItemIndex - 1].focus();
								}
							} else {
								if (focusedItemIndex === focusableElements.length - 1) {
									// 現在のフォーカスが最後の要素の場合、最初の要素にフォーカスを移動
									focusableElements[0].focus();
								} else {
									// 現在のフォーカスが最後の要素以外の場合、次の要素にフォーカスを移動
									focusableElements[focusedItemIndex + 1].focus();
								}
							}
						}
						// ESCキーが押された時
						if (event.key === "Escape") {
							event.preventDefault();
							$nav.classList.remove(a);
							$lang.classList.remove(a);
							$hamburger.classList.remove(a);
							$hamburger.querySelector('span').textContent = 'メニュー'
							fixedOffContainer();
							$hamburger.focus({ preventScroll: true });
						}
					}
				});
			}
		},
		matchHeight() {
			const $elements = document.querySelectorAll('[data-mh]');
			const groups = new Map();

			$elements.forEach( ele => { ele.style.height = 'auto'; } ); // 初期化

			$elements.forEach( ele => {
				const attributeName = ele.getAttribute('data-mh');
				if ( !groups.has( attributeName ) ) {
					groups.set( attributeName, [] );
				}
				groups.get( attributeName ).push( ele );
			} );
			groups.forEach( group => {
				const attributeGroups = new Map();
				group.forEach( ele => {
					const attributeName = ele.getAttribute('data-mh');
					const topPosition = ele.getBoundingClientRect().top;
					const key = attributeName + '_' + topPosition;
					if ( !attributeGroups.has( key ) ) {
						attributeGroups.set( key, [] );
					}
					attributeGroups.get( key ).push( ele );
				} );
				attributeGroups.forEach( (item) => {
					const maxHeight = Math.max( ...item.map( ele => ele.offsetHeight ) );
					item.forEach( (ele2) => {
						ele2.style.height = `${maxHeight}px`;
					});
				});
			} );
		},
		loadDelayScript(){
			var _this = this;
			_this.nextToggle();
			_this.easeScroll();
			_this.easeScrollHash();
			_this.pageTop();
			_this.pcHeaderNav();
			_this.spHeaderHamburger();
			_this.matchHeight();
			let resizeTime = 0;
			window.addEventListener('resize', () => {
				clearTimeout(resizeTime);
				resizeTime = setTimeout(() => { _this.matchHeight(); }, 300);
			});
		}
	};

	$(() => KURODA_HD.localDecision() ? KURODA_HD.localLoading() : KURODA_HD.loadDelayScript());
})(window.jQuery);
