(function ($, KURODA_HD){
	const a = 'is-active';
	const v = 'is-visible';
	const slideSpeed = '400';

	const spTabAccordion = () => {
		if (KURODA_HD.va.window.width <= KURODA_HD.va.device.sp) {
			$('.mod-tab-head').on('click', (e) => {
				$(e.currentTarget).next('.mod-tab-body').stop().slideToggle(slideSpeed);
			});
		}
	};
	const setNewsYearSelect = () => {
		if ($('.mod-selectYear').length) {
			let $selectYear = $('.mod-selectYear-select');
			const latestYearMonth = document.getElementsByClassName('rt_latest_year_month')[0];
			const year_month = latestYearMonth.textContent;
			const thisYear = (KURODA_HD.va.pathname.indexOf('/hd/en/') === -1) ? KURODA_HD.va.pathname.split('/')[3] : KURODA_HD.va.pathname.split('/')[4];
			if (year_month) {
				const startY = 2020;
				let myYear = year_month.substr(0, 4);
				let myMonth = year_month.substr(4, 2);
				let latestY = (myYear < 2000) ? myYear + 1900 : myYear;
				latestY = (myMonth > 3) ? latestY : latestY - 1;
				let html = '';

				for (let i = latestY; i >= startY; i--) {
					html += `<option value="${i}">${i}</option>`;
				}
				$selectYear.append(html);
				$selectYear.val(thisYear)
				$selectYear.on('change', (e) => {
					if (KURODA_HD.va.pathname.indexOf('/hd/en/') === -1) {
						location.href = `/hd/news/${$(e.currentTarget).val()}/`;
					} else {
						location.href = `/hd/en/news/${$(e.currentTarget).val()}/`;
					}
				});
			}
		}
	};
	const setNewsTabSwitch = () => {
		const setKey = (_key) => {
			if (_key === 'all') {
				$('.mod-newsList-item').each((idx, ele) => {
					$(ele).removeClass('di_none');
				});
			} else {
				$('.mod-newsList-item').each((idx, ele) => {
					if ($(ele).find('.mod-newsList-cat').hasClass(_key)) {
						$(ele).removeClass('di_none');
					} else {
						$(ele).addClass('di_none');
					}
				});
			}
		};
		if ($('.js-newsTab').length) {
			$('.js-newsTab').find('.mod-tab-item').on('click', (e) => {
				$('.js-newsTab').find('.mod-tab-item').each((idx, ele) => {
					$(ele).removeClass(a);
				});
				$(e.currentTarget).addClass(a);

				let key = $(e.currentTarget).attr('data-target');
				setKey(key);
				if(!($('.mod-newsList-item:not(.di_none)').length)) {
					$('.mod-newsList-item_noitem').removeClass('di_none');
				} else {
					$('.mod-newsList-item_noitem').addClass('di_none');
				}
			});
			if(location.hash) {
				let key = location.hash.slice(1);
				$('.mod-tab-item[data-target="all"]').removeClass(a);
				$(`.mod-tab-item[data-target="${key}"]`).addClass(a);
				setKey(key);
				if(!($('.mod-newsList-item:not(.di_none)').length)) {
					$('.mod-newsList-item_noitem').removeClass('di_none');
				} else {
					$('.mod-newsList-item_noitem').addClass('di_none');
				}
			}
		}
	};
	const modalImg = () => {
		$('.mod-modal').on('click', (e) => {
			let HTML = '<div class="mod-modal-overlay">';
			HTML +=
				'<div class="mod-modal-closeArea"></div>' +
				'<span class="mod-modal-closeBtn"><i class="icon icon-close"></i></span>' +
				`${$(e.currentTarget).find('img').prop('outerHTML')}</div>`;
			$('body').append(HTML);

			const $overlay = $('.mod-modal-overlay');
			$overlay.addClass('is-active');
			setTimeout(() => {
				$overlay.addClass('is-visible');

				$('.mod-modal-closeArea, .mod-modal-closeBtn').on('click', () => {
					$overlay.removeClass('is-visible');
					setTimeout(() => {
						$overlay.removeClass('is-active');
						$overlay.remove();
					}, 400);
				});
			});
		});
	};
	const fileSize = () => {
		$('.js-fileSize').each((idx, ele) => {
			$(ele).text(bytesToSize($(ele).text()));
		});

		function bytesToSize(bytes) {
			const sizes = ['Bytes', 'KB', 'MB', 'GB'];
			if (bytes === 0) return '0 Byte';
			let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
			let convSize = Math.floor(bytes / Math.pow(1024, i) * Math.pow(10, 1)) / Math.pow(10, 1);
			// KBのとき、小数点切り捨て
			if (i === 1) convSize = Math.floor(convSize);
			return convSize + sizes[i];
		}
	};
	const idxMainVisSlider = () => {
		$('.idx-mainVis').find('.__slider').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			speed: 1500,
			autoplay: true,
			autoplaySpeed: 5000,
			infinite: true,
			arrows: false,
			dots: false,
			fade: true,
		});
	};

	spTabAccordion();
	setNewsYearSelect();
	setNewsTabSwitch();
	modalImg();
	fileSize();
	idxMainVisSlider();

})(window.jQuery, window.KURODA_HD);
